<script setup lang="ts">
// icons
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiTwoBannerProps {
  contentType?: string | undefined;
  fields: any;
  index?: number;
  language?: string | undefined;
  slug?: string | undefined;
}
const props = withDefaults(defineProps<CiTwoBannerProps>(), {
  contentType: undefined,
  index: 0,
  language: undefined,
  slug: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
// const backendStore = useBackendStore();
const runtimeConfig = useRuntimeConfig();
const { fixImageUrlAndCheckWebp } = useWebp();
const { trackAdClickAndFollow: DTrackAdClickAndFollow, trackAdImpression, impressionId } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const itemCount = 2;

/********************
 * FUNCTIONS         *
 ********************/

// function getBackgroundImage(index) {
//   const imageSource = props.fields[`image_${index}`];
//   return fixImageUrl(backendStore.url, imageSource.url);
// }

function getImage(count, webp = false) {
  const imageSource = props.fields[`image_${count}`];
  return fixImageUrlAndCheckWebp(imageSource.url, webp).url;
}

function getContexts(n) {
  return [
    {
      schema: 'iglu:com.camparound/campinginfo_cs_two_banners/jsonschema/1-0-0',
      data: {
        category: props.fields[`category_${n}`],
        cta: props.fields[`cta_${n}`],
        headline: props.fields.headline,
        image: props.fields[`image_${n}`].url,
        link: props.fields[`link_${n}`],
        order: props.index + 1,
        position: n,
        title: props.fields[`title_${n}`],
      },
    },
  ];
}

function trackAdClickAndFollow(event, n) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.contentType,
    impressionId: impressionId.value,
    zoneId: props.slug,
    context: getContexts(n),
  });
}

function elementVisible(isVisible: boolean, n) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      targetUrl: props.fields[`link_${n}`],
      zoneId: props.slug,
      context: getContexts(n),
    });
  }
}
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--two-banners container my-6 grid grid-cols-2 gap-x-[30px] gap-y-4 pb-0"
  >
    <h2
      class="col-span-2 m-0 text-center"
      v-html="props.fields.headline"
    />
    <div
      v-for="n in itemCount"
      :key="n"
      class="col-span-2 md:col-span-1"
    >
      <a
        v-observe-visibility="{
          callback: (isVisible: boolean) => elementVisible(isVisible, n),
          once: true,
          throttle: 500,
          intersection: {
            threshold: 0.7,
          },
        }"
        :href="makeAbsoluteUrlRelative(props.fields[`link_${n}`], runtimeConfig.public.baseUrl)"
        :target="props.fields[`link_target_${n}`]"
        class="relative block h-[220px] overflow-hidden rounded hover:no-underline"
        @click="trackAdClickAndFollow($event, n)"
      >
        <picture>
          <source
            :srcset="getImage(n, true)"
            type="image/webp"
          >
          <source
            :srcset="getImage(n)"
            type="image/jpg"
          >
          <img
            :src="getImage(n)"
            class="two-banners__image absolute size-full object-cover"
            alt="banner"
            loading="lazy"
          >
        </picture>

        <div
          class="flex h-full after:absolute after:left-0 after:top-0 after:block after:h-full after:w-3/4 after:bg-gradient-to-r after:from-black after:to-transparent after:opacity-80 after:content-['']"
        >
          <div class="relative z-20 mt-auto w-full p-4 pt-12">
            <div class="max-w-full pt-2 text-sm font-medium leading-5 text-primary md:max-w-[80%]">
              {{ props.fields[`category_${n}`] }}
            </div>
            <div class="mb-4 max-w-full font-bold leading-5 text-white md:max-w-[80%]">
              {{ props.fields[`title_${n}`] }}
            </div>
            <button class="button button--primary mt-2">
              <span>{{ props.fields[`cta_${n}`] }}</span>
              <CiAwesomeIcon
                :icon="faChevronRight"
                ratio="0.6"
              />
            </button>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
