<script setup lang="ts">
type imageSource = {
  key: string;
  media: string;
  srcset: string;
  type: string;
};

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiImageTextProps {
  contentType?: string | undefined;
  fields: any;
  index?: number;
  language?: string | undefined;
  slug?: string | undefined;
}
const props = withDefaults(defineProps<CiImageTextProps>(), {
  contentType: undefined,
  index: 0,
  language: undefined,
  slug: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const backendStore = useBackendStore();
const viewport = useViewport();
const { trackAdImpression, impressionId } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const imageSrcJpg = computed(() => {
  return getImageSources('jpg');
});

const imageSrcWebp = computed(() => {
  return getImageSources('webp');
});

const contexts = computed(() => {
  return [
    {
      schema: 'iglu:com.camparound/campinginfo_cs_full_width_image/jsonschema/2-0-0',
      data: {
        headline: props.fields.headline,
        image_url: props.fields.small_image?.url || '',
        image_alt: props.fields.image_alt,
        image_description: props.fields.image_description,
        order: props.index + 1,
        image_type: viewport.isLessThan('md') ? 'small' : 'big',
      },
    },
  ];
});

/********************
 * FUNCTIONS         *
 ********************/
function elementVisible(isVisible: boolean) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      zoneId: props.slug,
      context: contexts.value,
    });
  }
}

function getImageSources(imageType: string): imageSource[] {
  const webp = imageType === 'webp';

  const imageSources: imageSource[] = [];
  const images = [props.fields.big_image, props.fields.small_image];
  images.forEach((img, idx) => {
    const url = addWebp(fixImageUrl(backendStore.url, img.url), webp);
    let minWidth = 0;
    if (idx < images.length - 1) {
      minWidth = 768;
    }
    imageSources.push({
      srcset: `${url} ${img.width}w,`,
      media: `(min-width: ${minWidth}px)`,
      type: `image/${imageType}`,
      key: `${imageType}.${idx}`,
    });
  });
  return imageSources;
}
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--image container mt-5 pb-3 first:mt-0"
  >
    <div
      v-observe-visibility="{
        callback: elementVisible,
        once: true,
        throttle: 500,
        intersection: {
          threshold: 0.3,
        },
      }"
      class="row"
    >
      <div class="col-12">
        <h2
          class="m-0 mb-4 text-center"
          v-html="props.fields.headline"
        />
      </div>
      <div
        v-if="props.fields.big_image || props.fields.small_image"
        class="col-12"
      >
        <figure class="m-0">
          <div class="relative pb-[57.971%] md:pb-[40.35%]">
            <picture>
              <source
                v-for="source of imageSrcWebp"
                :key="source.key"
                :srcset="source.srcset"
                :media="source.media"
                :type="source.type"
              >
              <source
                v-for="source of imageSrcJpg"
                :key="source.key"
                :srcset="source.srcset"
                :media="source.media"
                :type="source.type"
              >
              <img
                v-if="fields.small_image"
                class="absolute left-0 top-0 h-auto w-full rounded"
                :src="props.fields.small_image.url"
                :alt="props.fields.image_alt"
                loading="eager"
              >
            </picture>
          </div>
          <figcaption
            v-if="props.fields.image_description"
            class="mt-1 text-center text-gray"
            v-html="props.fields.image_description"
          />
        </figure>
      </div>
    </div>
  </div>
</template>
