<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiTeaserProps {
  contentType?: string | undefined;
  fields: any;
  index?: number;
  language?: string | undefined;
  slug?: string | undefined;
}
const props = withDefaults(defineProps<CiTeaserProps>(), {
  contentType: undefined,
  index: 0,
  language: undefined,
  slug: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
// const backendStore = useBackendStore();
const runtimeConfig = useRuntimeConfig();
const { fixImageUrlAndCheckWebp } = useWebp();
const { trackAdClickAndFollow: DTrackAdClickAndFollow, trackAdImpression, impressionId } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const itemCount = 4;

/********************
 * FUNCTIONS         *
 ********************/
function getImage(count: number, webp = false) {
  const imageSource = props.fields[`image_${count}`];
  return fixImageUrlAndCheckWebp(imageSource.url, webp).url;
}

function getContexts(n) {
  return [
    {
      schema: 'iglu:com.camparound/campinginfo_cs_four_teasers/jsonschema/1-0-0',
      data: {
        title: props.fields[`title_${n}`],
        cta: props.fields[`cta_${n}`],
        link: props.fields[`link_${n}`],
        image: props.fields[`image_${n}`].url,
        order: props.index + 1,
        position: n,
      },
    },
  ];
}

function trackAdClickAndFollow(event, n) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.contentType,
    impressionId: impressionId.value,
    zoneId: props.slug,
    context: getContexts(n),
  });
}

function elementVisible(isVisible: boolean, n) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      targetUrl: props.fields[`link_${n}`],
      zoneId: props.slug,
      context: getContexts(n),
    });
  }
}
</script>

<template>
  <div
    v-if="Object.keys(props.fields).length"
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--teaser container my-12 grid grid-cols-4 gap-x-[30px] gap-y-4 pb-0"
  >
    <div
      v-for="count in itemCount"
      :key="count"
      class="col-span-4 flex md:col-span-2 lg:col-span-1"
    >
      <a
        v-observe-visibility="{
          callback: (isVisible: boolean) => elementVisible(isVisible, count),
          once: true,
          throttle: 500,
          intersection: {
            threshold: 0.7,
          },
        }"
        :href="makeAbsoluteUrlRelative(props.fields[`link_${count}`], runtimeConfig.public.baseUrl)"
        :target="props.fields[`link_target_${count}`]"
        class="relative block h-auto w-full rounded-lg shadow-ci after:absolute after:left-0 after:top-0 after:block after:size-full after:rounded-lg after:opacity-0 after:shadow-ci-sm after:transition-opacity after:duration-500 after:content-[''] hover:no-underline hover:after:opacity-100"
        @click.capture="trackAdClickAndFollow($event, count)"
      >
        <div class="relative h-[14.5rem] w-full overflow-hidden rounded-lg">
          <picture>
            <source
              :srcset="getImage(count, true)"
              type="image/webp"
            >
            <source
              :srcset="getImage(count)"
              type="image/jpg"
            >
            <img
              :src="getImage(count)"
              class="absolute size-full object-cover"
              alt="teaser"
              loading="lazy"
            >
          </picture>

          <div
            class="relative flex h-full after:absolute after:left-0 after:top-0 after:block after:size-full after:bg-gradient-to-b after:from-transparent after:from-50% after:to-black after:content-['']"
          >
            <div class="z-20 m-0 mt-auto p-4 font-bold leading-5 text-white">
              {{ props.fields[`title_${count}`] }}
            </div>
          </div>
        </div>
        <div class="rounded-b-lg bg-white p-4">
          <CiButton class="button button--primary icon-absolute w-full"><span>{{ props.fields[`cta_${count}`] }}</span></CiButton>
        </div>
      </a>
    </div>
  </div>
</template>

<style></style>
