export default function useMatchingSearchQueryForMap() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();
  const router = useRouter();

  /********************
   * FUNCTIONS         *
   ********************/
  function getMatchingSearchQueryForMap({ entireListUrl = null, isAS }: { entireListUrl?: any; isAS: boolean }) {
    let internalRoute = route;

    if (entireListUrl) {
      internalRoute = router.resolve(entireListUrl);
    }

    const { params, query } = internalRoute;
    const baseRouteName = getRouteBaseName(internalRoute);
    const isCitySearch = baseRouteName === RBN_CITY_SEARCH;
    const isCountrySearch = baseRouteName === RBN_COUNTRY_SEARCH;
    const isFederalState = baseRouteName === RBN_FEDERAL_STATE_SEARCH;
    const isRegionSearch = baseRouteName === RBN_REGION_SEARCH;

    const searchQuery = { ...query };

    if (isAS) {
      searchQuery.only_availabilities = 'true';
    }

    if (isCountrySearch || isFederalState) {
      searchQuery.country = params.countrySlug;
    }
    if (isFederalState) {
      searchQuery.federal_state = params.federalStateSlug;
    }
    if (isRegionSearch) {
      searchQuery.region = params.regionSlug;
    }
    if (isCitySearch) {
      searchQuery.city = params.citySlug;
    }

    return searchQuery;
  }

  return {
    getMatchingSearchQueryForMap,
  };
}
