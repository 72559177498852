<script setup lang="ts">
// icons
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

// utils
import { translateUrl } from '@/utils/i18n';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiCampsiteListProps {
  contentType?: string | undefined;
  fields: any;
  index?: number | null;
  language?: string | undefined;
  slug?: string | null;
}
const props = withDefaults(defineProps<CiCampsiteListProps>(), {
  contentType: undefined,
  index: null,
  language: undefined,
  slug: null,
});

/********************
 * COMPOSITIONS      *
 ********************/
const route = useRoute();
const { $gettext } = useGettext();
const { getMatchingSearchQueryForMap } = useMatchingSearchQueryForMap();
const { impressionId, trackAdImpression, trackAdClickAndFollow: DTrackAdClickAndFollow } = useTracking();
const { locale, localeCodes } = useI18n();
const { openModal, closeModal } = useCiModal();

/********************
 * REFS & VARS       *
 ********************/
const entireListUrl = computed(() => {
  if (!props.fields.campsite_list_url) {
    return '';
  }
  return translateUrl(props.fields.campsite_list_url, localeCodes.value, locale.value);
});

const contexts = computed(() => {
  let contexts;
  if (props.contentType === 'campsitelist') {
    contexts = [
      {
        schema: 'iglu:com.camparound/campinginfo_cs_campsite_list/jsonschema/1-0-0',
        data: {
          headline: props.fields.headline,
          campsite_list_url: entireListUrl.value,
        },
      },
    ];
  } else if (props.contentType === 'manualcampsitelist') {
    contexts = [
      {
        schema: 'iglu:com.camparound/campinginfo_cs_manual_campsite_list/jsonschema/1-0-0',
        data: {
          headline: props.fields.headline,
          show_ranking: props.fields.show_ranking,
        },
      },
    ];
  }
  props.fields.campsites.results.forEach((campsite) => {
    contexts.push({
      schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
      data: {
        booked_products: campsite.purchases || null,
        camping_id: campsite.camping_id,
        civ2_id: campsite.id,
        slug: campsite.slug,
      },
    });
  });
  return contexts;
});

/********************
 * FUNCTIONS         *
 ********************/
function elementVisible(isVisible: boolean) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      zoneId: 'entire-list',
      context: contexts.value,
    });
  }
}

function trackAdClickAndFollow(event) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.contentType,
    impressionId: impressionId.value,
    zoneId: 'entire-list',
    context: contexts.value,
  });
}

function getCampsiteMapData(campsite) {
  return {
    id: campsite.id,
    name: campsite.name,
    rating_avg_overall: campsite.rating_avg_overall,
    rating_count: campsite.rating_count,
    showEffect: true,
    slug: campsite.slug,
  };
}

function showMapModal(campsite: any, passQuery: boolean = false) {
  const CiMapModal = defineAsyncComponent({
    loader: () => import('@/components/map/CiMapModal/CiMapModal.vue'),
  });

  let query = {};
  if (passQuery) {
    query = route.query;
  }

  openModal(
    {
      component: CiMapModal,
      attrs: {
        query: {
          ...getMatchingSearchQueryForMap({ entireListUrl: entireListUrl.value || null, isAS: false }),
          ...query,
        },
        campsite: getCampsiteMapData(campsite),
        latitude: `${campsite.geo_position.lat}`,
        longitude: `${campsite.geo_position.lon}`,
        title: campsite.name,
        name: 'CiMapModal',
        onClose: () => {
          closeModal('CiMapModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'max-w-[1140px] w-full h-[80%]',
    },
  );
}

/********************
 * HOOKS             *
 ********************/
// done globally in modal plugin
// onBeforeUnmount(() => {
//   closeModal('CiMapModal');
// });
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    v-observe-visibility="{
      callback: elementVisible,
      once: true,
      throttle: 500,
      intersection: {
        threshold: 0.1,
      },
    }"
    :lang="props.language"
    class="section section--campsite-list my-12 pb-4"
  >
    <div
      v-if="props.fields.headline"
      class="container"
    >
      <h2
        class="m-0 mb-4 text-center"
        v-html="props.fields.headline"
      />
    </div>

    <div
      :class="{ 'pb-12': !props.fields.campsite_list_url }"
      style="background: linear-gradient(to bottom, transparent 100px, #f5f5f5 100px)"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-9 mx-auto">
            <ul class="m-0 list-none p-0">
              <li
                v-for="(campsite, idx) in props.fields.campsites.results"
                :key="`campsite-${campsite.id}`"
                class="m-0 p-0 pb-5 last-of-type:border-0 last-of-type:pb-0 md:border-0 md:pb-[1.875rem]"
              >
                <CiCampsiteTileAs
                  :campsite="campsite"
                  :snowplow-context="{
                    bannerId: 'content',
                    position: idx + 1,
                  }"
                  :flex-availabilities="[]"
                  :allow-sponsored="false"
                  :ranking="props.fields.show_ranking ? idx + props.fields.ranking_offset : null"
                  @location-clicked="showMapModal(campsite, false)"
                />
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="props.fields.campsite_list_url"
          class="mt-6 flex justify-center pb-6"
        >
          <a
            :href="entireListUrl"
            class="button button--primary-outline"
            @click.prevent="trackAdClickAndFollow"
          >
            <span>{{ $gettext('entire list') }}</span>
            <CiAwesomeIcon
              :icon="faChevronRight"
              ratio="0.6"
            />
          </a>
        </div>

        <div
          v-if="props.fields.cta_link"
          class="mt-6 flex justify-center pb-6"
        >
          <a
            :href="props.fields.cta_link"
            class="button button--primary-outline"
            @click="trackAdClickAndFollow"
          >
            <span>{{ props.fields.cta_text }}</span>
            <CiAwesomeIcon
              :icon="faChevronRight"
              ratio="0.6"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
