<script setup lang="ts">
// icons
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiImageTextProps {
  contentType?: string | undefined;
  fields: any;
  index?: number;
  language?: string | undefined;
  slug?: string | undefined;
}
const props = withDefaults(defineProps<CiImageTextProps>(), {
  contentType: undefined,
  index: 0,
  language: undefined,
  slug: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const backendStore = useBackendStore();
const runtimeConfig = useRuntimeConfig();
const { delegateLinksToRouter } = useDelegateToRouter();
const { trackAdClickAndFollow: DTrackAdClickAndFollow, trackAdImpression, impressionId } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const contexts = shallowRef([
  {
    schema: 'iglu:com.camparound/campinginfo_cs_image_text/jsonschema/1-0-2',
    data: {
      pre_headline: props.fields.pre_headline,
      headline: props.fields.headline,
      image_alt: props.fields.image_alt,
      image_description: props.fields.image_description,
      image_right: props.fields.image_right,
      cta: props.fields.cta,
      link: props.fields.cta_link,
      image: props.fields.image && props.fields.image.url,
      order: props.index + 1,
      text: props.fields.text,
    },
  },
]);

const image = computed(() => {
  return {
    url: fixImageUrl(backendStore.url, props.fields.image.url),
    width: props.fields.image.width,
    height: props.fields.image.height,
  };
});

/********************
 * FUNCTIONS         *
 ********************/
function trackAdClickAndFollow(event: MouseEvent) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.contentType,
    impressionId: impressionId.value,
    zoneId: props.slug,
    context: contexts.value,
  });
}

function elementVisible(isVisible: boolean) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      targetUrl: props.fields.cta_link,
      zoneId: props.slug,
      context: contexts.value,
    });
  }
}
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--image-text container my-12 pb-4"
  >
    <div
      v-observe-visibility="{
        callback: elementVisible,
        once: true,
        throttle: 500,
        intersection: {
          threshold: 0.3,
        },
      }"
      class="row"
    >
      <div
        class="col-12 col-lg-7 order-1"
        :class="{ 'lg:order-2': props.fields.image_right }"
      >
        <!-- mb-3 -->
        <figure class="m-0">
          <img
            v-if="props.fields.image"
            class="block h-auto w-full rounded"
            :src="image.url"
            :width="image.width"
            :height="image.height"
            :alt="props.fields.image_alt"
            loading="lazy"
          >
          <figcaption
            v-if="props.fields.image_description"
            class="mt-1 text-sm text-gray"
            :class="{ 'lg:text-right': props.fields.image_right }"
            v-html="props.fields.image_description"
          />
        </figure>
      </div>

      <div
        class="col-12 col-lg-5 order-2 mt-4 pt-0 lg:mt-0 lg:pt-6"
        :class="{ 'lg:order-1': props.fields.image_right }"
      >
        <h2
          v-if="props.fields.pre_headline"
          class="m-0 font-normal"
          v-html="props.fields.pre_headline"
        />
        <h2
          v-if="props.fields.headline"
          class="font-bold"
          v-html="props.fields.headline"
        />

        <div
          v-if="props.fields.text"
          @click="delegateLinksToRouter"
          v-html="props.fields.text"
        />
        <a
          v-if="props.fields.cta_link"
          :href="makeAbsoluteUrlRelative(fields.cta_link, runtimeConfig.public.baseUrl)"
          :target="props.fields.cta_link_target"
          class="button button--primary"
          @click="trackAdClickAndFollow"
        >
          <span>{{ props.fields.cta }}</span>
          <CiAwesomeIcon
            :icon="faChevronRight"
            ratio="0.6"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<style></style>
