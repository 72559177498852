<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiFAQAccordionProps {
  fields: any;
  language?: string | undefined;
}
const props = withDefaults(defineProps<CiFAQAccordionProps>(), {
  language: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const jsonLdStore = useJsonLdStore();
const { delegateLinksToRouter } = useDelegateToRouter();
const { isOpen, onToggle } = useAccordion();

/********************
 * INITIALIZATION    *
 ********************/
// faqs will only be added on SSR
if (import.meta.server) {
  const items = [];
  if (props.fields?.content) {
    props.fields.content.forEach((content) => {
      items.push({
        '@type': 'Question',
        'name': content.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': content.answer,
        },
      });
    });
  }
  jsonLdStore.addFaqs({ items });
}

/********************
 * HOOKS             *
 ********************/
onBeforeUnmount(() => {
  jsonLdStore.removeFaqs();
});
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--faq-accordion container my-12 pb-4"
  >
    <div class="row">
      <div class="col-12">
        <h2
          v-if="props.fields.headline"
          class="m-0 mb-4 text-center"
          v-html="props.fields.headline"
        />
        <CiAccordion
          v-for="(content, index) in props.fields.content"
          :key="index"
          :scroll-to-item="false"
          :is-last="index === props.fields.content.length - 1"
          :show="isOpen(index)"
          @accordion-toggle="onToggle(index)"
        >
          <template #header>
            <h5
              class="m-0 py-6 text-black"
              v-html="content.question"
            />
          </template>
          <div class="pb-2">
            <div class="container">
              <div class="row">
                <div
                  class="col-12 col-lg-10 text-black"
                  @click="delegateLinksToRouter"
                  v-html="content.answer"
                />
              </div>
            </div>
          </div>
        </CiAccordion>
      </div>
    </div>
  </div>
</template>

<style></style>
